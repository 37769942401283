<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 14 11"
    class="icon"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        :fill="color"
        fill-rule="nonzero"
      >
        <path
          d="M861.568 496.432c-.575-.576-1.509-.576-2.084 0l-6.326 6.325-2.643-2.641c-.574-.576-1.509-.576-2.083 0-.576.575-.576 1.508 0 2.084l3.684 3.684c.287.288.664.432 1.042.432.377 0 .754-.144 1.042-.432l7.368-7.369c.576-.575.576-1.508 0-2.083z"
          transform="translate(-848 -496)"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 18
    },
    variant: {
      type: String,
      default: 'default' // default, acent, secondary
    },
  },
  setup(props) {
    const width = props.size
    const height = (17 / 20) * width

    const color = (() => {
      if (props.variant === 'default') {
        return '#7F8563'
      }

      if (props.variant === 'acent') {
        return '#017074'
      }

      if (props.variant === 'secondary') {
        return '#82afa5'
      }

      // Default: light
      return '#7F8563'
    })()

    return {
      width,
      height,

      color,
    }
  }
})
</script>
